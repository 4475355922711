import type { HeadFC, PageProps } from 'gatsby'
import React from 'react'
import { BsArrowRightShort } from 'react-icons/bs'

import { LanguageHeaders } from '../components/LanguageHeaders'
import Layout from '../components/Layout'
import Link from '../components/Link'
import { useTranslate } from '../utils/translate'

const LegalPage = () => {
  const t = useTranslate()

  return (
    <Layout light>
      <main className="m-auto w-full lg:max-w-screen-lg grid grid-flow-row gap-20 p-4 lg:px-0 pt-24">
        <div className="text-center space-y-4 mx-auto">
          <h2>{t('legal.title')}</h2>
          <p className="max-w-xl">{t('legal.subtitle')}</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
          <div className="space-y-2">
            <h4>{t('legal.sub_processors.title')}</h4>
            <p>{t('legal.sub_processors.subtitle')}</p>
            <p>
              <Link to="/sub_processors" className="text-primary flex items-center mr-1">
                {t('readMore')}
                <BsArrowRightShort size={24} />
              </Link>
            </p>
          </div>
          <div className="space-y-2">
            <h4>{t('legal.privacy_policy.title')}</h4>
            <p>{t('legal.privacy_policy.subtitle')}</p>
            <p>
              <Link to="/privacy_policy" className="text-primary flex items-center mr-1">
                {t('readMore')}
                <BsArrowRightShort size={24} />
              </Link>
            </p>
          </div>
          <div className="space-y-2">
            <h4>{t('legal.cookie_policy.title')}</h4>
            <p>{t('legal.cookie_policy.subtitle')}</p>
            <p>
              <Link to="/cookie_policy" className="text-primary flex items-center mr-1">
                {t('readMore')}
                <BsArrowRightShort size={24} />
              </Link>
            </p>
          </div>
          <div className="space-y-2">
            <h4>{t('legal.impresum.title')}</h4>
            <p>{t('legal.impresum.subtitle')}</p>

            <p>
              <Link to="/impresum" className="text-primary flex items-center mr-1">
                {t('readMore')}
                <BsArrowRightShort size={24} />
              </Link>
            </p>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default LegalPage

export const Head: HeadFC = ({ pageContext }: any) => {
  const language = pageContext.language
  const translations: any = pageContext.translations
  return (
    <>
      <title>{translations['legal.meta.title']}</title>
      <meta name="description" content={translations['legal.meta.description']} />
      <meta name="robots" content="index, follow" />
      <LanguageHeaders pathname={pageContext.defaultPath} locale={language} />
    </>
  )
}
